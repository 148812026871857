import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const WikiLink = ({ page }) => (
  <Link to={page.frontmatter.slug}>
    {page.frontmatter.title} ({page.frontmatter.date})
  </Link>
);

const WikiIndex = ({ tree }) => {
  const subPaths = tree.children.map(p => {
    return <WikiIndex tree={p} key={p.key} />;
  });
  return (
    <li>
      {tree.edge ? <WikiLink key={tree.edge.node.id} page={tree.edge.node} /> : tree.name}
      <ul>{subPaths}</ul>
    </li>
  );
};

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const pageTree = treeize(edges.filter(edge => !!edge.node.frontmatter.date));
  return (
    <Layout>
      <SEO title="Home" />
      <p>Welcome to PMWIKI</p>
      <WikiIndex tree={pageTree[0]} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMdx(sort: { order: ASC, fields: [frontmatter___slug] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date
            slug
            title
          }
        }
      }
    }
  }
`;

// treeize builds a tree from the page slugs, which may or may not differ from the actual filesystem hierarchy
function treeize(edges) {
  const tree = [];

  for (const edge of edges) {
    const slug = edge.node.frontmatter.slug.split('/');
    const currentPath = [];
    let currentLevel = tree;
    for (const [i, part] of slug.entries()) {
      currentPath.push(part);
      let existingPath = currentLevel.find(p => p.name === part);
      if (!existingPath) {
        existingPath = {
          name: part,
          key: currentPath.join('/'),
          children: [],
        };
        currentLevel.push(existingPath);
      }

      currentLevel = existingPath.children;
      if (i === slug.length - 1) {
        existingPath.edge = edge;
      }
    }
  }
  return tree;
}
